import {
  CloseOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  MenuFoldOutlined,
  ReadOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Text } from '@hello-ai/ar_shared/src/components/Text'
import { t } from '@hello-ai/ar_shared/src/modules/i18n/translations/affiliate'
import {
  localeData,
  supportedLocales,
} from '@hello-ai/ar_shared/src/modules/locale'
import { Drawer, Dropdown, Grid, Layout, Menu } from 'antd'
import Link from 'antd/lib/typography/Link'
import { signOut } from 'models/Auth'
import Bank from 'pages/Bank'
import NotFound from 'pages/Error/NotFound'
import Home from 'pages/Home'
import Identification from 'pages/Identification'
import Media from 'pages/Media'
import Medium from 'pages/Medium'
import Support from 'pages/Support'
import React, { useState } from 'react'
import { View } from 'react-native'
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import { getLocale } from '../modules/locale'

const { Header, Sider, Content } = Layout
const { useBreakpoint } = Grid

function Menus({ onClick }: { onClick: ({ key }: { key: string }) => void }) {
  const location = useLocation()
  const pathname = location.pathname
  let defaultSelectedKeys: string[] = []
  const defaultOpenKeys: string[] = []

  if (pathname.includes('media')) {
    defaultSelectedKeys = ['media']
  } else if (pathname.includes('support')) {
    defaultSelectedKeys = ['support']
  }

  return (
    <Menu
      theme="dark"
      mode="inline"
      items={[
        {
          label: t('メディア'),
          key: 'media',
          icon: <ReadOutlined style={{ fontSize: 16 }} />,
        },
        {
          label: t('サポート'),
          key: 'support',
          icon: <InfoCircleOutlined style={{ fontSize: 16 }} />,
        },
      ]}
      defaultOpenKeys={defaultOpenKeys}
      defaultSelectedKeys={defaultSelectedKeys}
      onClick={onClick}
    />
  )
}

export default function DashboardNavigation() {
  const [drawerVisible, setDrawerVisible] = useState(false)
  const navigate = useNavigate()
  const screens = useBreakpoint()
  const xs = screens.xs
  const locale = getLocale()

  const onClose = () => {
    setDrawerVisible(false)
  }

  const onClick = ({ key }: { key: string }) => {
    if (key === 'media') {
      navigate('/media')
    } else if (key === 'support') {
      navigate('/support')
    }
    onClose()
  }

  return (
    <Layout hasSider={!xs}>
      {xs ? (
        <Drawer
          placement="left"
          closeIcon={<CloseOutlined style={{ color: 'white' }} />}
          onClose={onClose}
          open={drawerVisible}
          headerStyle={{
            padding: '16px',
            backgroundColor: '#041527',
          }}
          bodyStyle={{
            padding: 0,
            backgroundColor: '#041527',
          }}
          width="75%"
        >
          <Menus onClick={onClick} />
        </Drawer>
      ) : (
        <Sider theme="dark">
          <Menus onClick={onClick} />
        </Sider>
      )}
      <Layout>
        <Header
          style={{
            backgroundColor: 'white',
            padding: 0,
          }}
        >
          <View
            style={{
              height: '100%',
              paddingHorizontal: 20,
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <View
              style={{ flex: 1, flexDirection: 'row', alignItems: 'center' }}
            >
              {xs && (
                <MenuFoldOutlined
                  style={{ fontSize: 24 }}
                  onClick={() => setDrawerVisible(true)}
                />
              )}
            </View>
            <View
              style={{
                flex: 3,
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text style={{ fontWeight: '600', fontSize: 20 }}>
                AR Affiliate
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                flexDirection: 'row',
                justifyContent: 'flex-end',
                alignItems: 'center',
                gap: 16,
              }}
            >
              <Dropdown
                menu={{
                  items: supportedLocales.map((supportedLocale) => {
                    return {
                      label: (
                        <Link
                          href={window.location.href.replace(
                            locale,
                            supportedLocale
                          )}
                        >
                          {localeData[supportedLocale].label}
                        </Link>
                      ),
                      key: supportedLocale,
                    }
                  }),
                }}
                trigger={['click']}
              >
                <View
                  style={{ flexDirection: 'row', alignItems: 'center', gap: 4 }}
                >
                  <GlobalOutlined style={{ fontSize: 16 }} />
                  <Text style={{ fontSize: 14, fontWeight: '600' }}>
                    {localeData[locale].label}
                  </Text>
                </View>
              </Dropdown>
              <Dropdown
                menu={{
                  items: [
                    {
                      label: <Link>{t('ログアウト')}</Link>,
                      key: 'signout',
                      onClick: () => signOut(),
                    },
                  ],
                }}
                trigger={['click']}
              >
                <UserOutlined style={{ fontSize: 24 }} />
              </Dropdown>
            </View>
          </View>
        </Header>
        <Content
          style={{
            padding: xs ? '32px 20px' : '32px',
            height: '100%',
            overflowY: 'scroll',
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/media" element={<Media />} />
            <Route
              path="/media/:mediumId/identification"
              element={<Identification />}
            />
            <Route path="/media/:mediumId/bank" element={<Bank />} />
            <Route path="/media/:mediumId/*" element={<Medium />} />
            <Route path="/support/*" element={<Support />} />
            <Route path="/*" element={<NotFound />} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  )
}
