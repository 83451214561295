import React, { useEffect } from 'react'
import { View } from 'react-native'
import { useNavigate } from 'react-router-dom'

export default function Dashboard() {
  const navigate = useNavigate()

  useEffect(() => {
    // NOTE: Home廃止に伴いMediaルートへリダイレクトする
    navigate('/media', { replace: true })
  }, [navigate])

  return <View />
}
